import { createRouter, createWebHistory } from 'vue-router'
import store from "@/store";
import HomeView from '../views/HomeView.vue'
import LoginView from '../views/LoginView.vue'


const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    children: [
   {
    path: '/countries',
    name: 'Countries',
       meta: { permission: "countries" ,type : "parent" },
       component: () => import ('../components/Countries/List.vue'),
    children: [
      {
        path: 'create',
        name: 'CountriesCreate',
        meta: { permission: "countries_create" ,type : "child"},
          component: () => import ('../components/Countries/Create.vue')
      }, {
        path: 'update',
        name: 'CountriesUpdate',
         meta: { permission: "countries_edit" ,type : "child"},
            component: () => import ('../components/Countries/Update.vue')
      }, {
        path: 'show',
        name: 'CountriesShow',
            meta: { permission: "countries_read" ,type : "child"},
            component: () => import ('../components/Countries/Show.vue')
      }
    ]
  }, 
   {
    path: '/currencies',
    name: 'Currencies',
    meta: { permission: "currencies" ,type : "parent" },
       component: () => import ('../components/Currencies/List.vue'),
    children: [
      {
        path: 'create',
        name: 'CurrenciesCreate',
        meta: { permission: "currencies_create" ,type : "child" },
          component: () => import ('../components/Currencies/Create.vue')
      }, {
        path: 'update',
        name: 'CurrenciesUpdate',
        meta: { permission: "currencies_edit" ,type : "child" },
            component: () => import ('../components/Currencies/Update.vue')
      }
      , {
        path: 'show',
        name: 'CurrenciesShow',
        meta: { permission: "currencies_read" ,type : "child" },
       component: () => import ('../components/Currencies/Show.vue')
      }
    ]
  },
   {
    path: '/fields',
    name: 'Fields',
    meta: { permission: "fields" ,type : "parent" },
    component: () => import ('../components/Fields/List.vue'),
    children: [
      {
        path: 'create',
        name: 'FieldsCreate',
        meta: { permission: "fields_create" ,type : "child" },
        component: () => import ('../components/Fields/Create.vue')
      }, {
        path: 'update',
        name: 'FieldsUpdate',
        meta: { permission: "fields_edit" ,type : "child" },
        component: () => import ('../components/Fields/Update.vue')
      }
      , {
        path: 'show',
        name: 'FieldsShow',
        meta: { permission: "field_read" ,type : "child" },
        component: () => import ('../components/Fields/Show.vue')
      }
    ]
  },
   {
    path: '/sectors',
    name: 'Sectors',
       meta: { permission: "sectors" ,type : "parent" },
       component: () => import ('../components/Sectors/List.vue'),
    children: [
      {
        path: 'create',
        name: 'SectorsCreate',
          meta: { permission: "sectors_create" ,type : "child" },
          component: () => import ('../components/Sectors/Create.vue')
      }, {
        path: 'update',
        name: 'SectorsUpdate',
            meta: { permission: "sectors_edit" ,type : "child" },
            component: () => import ('../components/Sectors/Update.vue')
      }
      , {
        path: 'show',
        name: 'SectorsShow',
            meta: { permission: "sectors_read" ,type : "child" },
            component: () => import ('../components/Sectors/Show.vue')
      }
    ]
  },
   {
    path: '/samples',
    name: 'Samples',
       meta: { permission: "samples" ,type : "parent" },
       component: () => import ('../components/Samples/List.vue'),
    children: [
      {
        path: 'create',
        name: 'SamplesCreate',
          meta: { permission: "samples_create" ,type : "child" },
          component: () => import ('../components/Samples/Create.vue')
      }, {
        path: 'update',
        name: 'SamplesUpdate',
            meta: { permission: "samples_edit" ,type : "child" },
            component: () => import ('../components/Samples/Update.vue')
      }
      , {
        path: 'show',
        name: 'SamplesShow',
            meta: { permission: "samples_read" ,type : "child" },
            component: () => import ('../components/Samples/Show.vue')
      }
    ]
  },
   {
    path: '/units',
    name: 'Units',
       meta: { permission: "units" ,type : "parent" },
       component: () => import ('../components/Units/List.vue'),
    children: [
      {
        path: 'create',
        name: 'UnitsCreate',
          meta: { permission: "units_create" ,type : "child" },
          component: () => import ('../components/Units/Create.vue')
      }, {
        path: 'update',
        name: 'UnitsUpdate',
            meta: { permission: "units_edit" ,type : "child" },
            component: () => import ('../components/Units/Update.vue')
      }
      , {
        path: 'show',
        name: 'UnitsShow',
        meta: { permission: "units_read" ,type : "child" },
        component: () => import ('../components/Units/Show.vue')
      }
    ]
  },
   {
            path: '/extra-units',
            name: 'ExtraUnits',
            meta: { permission: "extra_units" ,type : "parent" },
            component: () => import ('../components/ExtraUnits/List.vue'),
            children: [
                {
                    path: 'create',
                    name: 'ExtraUnitsCreate',
                    meta: { permission: "extra_units-create" ,type : "child" },
                    component: () => import ('../components/ExtraUnits/Create.vue')
                }, {
                    path: 'update',
                    name: 'ExtraUnitsUpdate',
                    meta: { permission: "extra_units-edit" ,type : "child" },
                    component: () => import ('../components/ExtraUnits/Update.vue')
                }
                , {
                    path: 'show',
                    name: 'ExtraUnitsShow',
                    meta: { permission: "extra_units-read" ,type : "child" },
                    component: () => import ('../components/ExtraUnits/Show.vue')
                }
            ]
        },
   {
    path: '/packages',
    name: 'Packages',
    meta: { permission: "packages" ,type : "parent" },
            component: () => import ('../components/Packages/List.vue'),
    children: [
      {
        path: 'create',
        name: 'PackagesCreate',
          meta: { permission: "packages_create" ,type : "child" },
          component: () => import ('../components/Packages/Create.vue')
      }, {
        path: 'update',
        name: 'PackagesUpdate',
            meta: { permission: "packages_edit" ,type : "child" },
            component: () => import ('../components/Packages/Update.vue')
      }
      , {
        path: 'show',
        name: 'PackagesShow',
            meta: { permission: "packages_read" ,type : "child" },
            component: () => import ('../components/Packages/Show.vue')
      }
    ]
  },
   {
            path: '/profile',
            name: 'ProfileUpdate',
            component: () => import ('../components/Profile/Profile.vue'),
        },
   {
            path: '/settings',
            name: 'SettingsUpdate',
            meta: { permission: "settings" ,type : "parent" },
            component: () => import ('../components/Settings/Settings.vue'),
        },
   {
    path: '/basic-data',
    name: 'BasicData',
       meta: { permission: "basic_datas" ,type : "parent" },
       component: () => import ('../components/BasicData/List.vue'),
    children: [
      {
        path: 'create',
        name: 'BasicDataCreate',
          meta: { permission: "basic_datas_create" ,type : "child" },
          component: () => import ('../components/BasicData/Create.vue')
      }, {
        path: 'update',
        name: 'BasicDataUpdate',
            meta: { permission: "basic_datas_edit" ,type : "child" },
            component: () => import ('../components/BasicData/Update.vue')
      }
      , {
        path: 'show',
        name: 'BasicDataShow',
            meta: { permission: "basic_datas_read" ,type : "child" },
            component: () => import ('../components/BasicData/Show.vue')
      }
    ]
  },
   {
        path: '/products',
        name: 'Products',
       meta: { permission: "products" ,type : "parent" },
       component: () => import ('../components/Product/List.vue'),
        children: [
          {
            path: 'create',
            name: 'ProductCreate',
            meta: { permission: "products_create" ,type : "child" },
              component: () => import ('../components/Product/Create.vue')
          }, {
            path: 'update',
            name: 'ProductUpdate',
            meta: { permission: "products_edit" ,type : "child" },
            component: () => import ('../components/Product/Update.vue')
          },
          {
            path: 'show',
            name: 'ProductsShow',
            meta: { permission: "products_read" ,type : "child" },
            component: () => import ('../components/Product/Show.vue')
          }
        ]
      },
   {
        path: '/products-prices',
        name: 'ProductsPrices',
       meta: { permission: "products" ,type : "parent" },
       component: () => import ('../components/ProductsPrices/List.vue'),
        props: true,
        children: [
          {
            path: 'create',
            name: 'ProductsPricesCreate',
              meta: { permission: "products_create" ,type : "child" },
              component: () => import ('../components/ProductsPrices/Create.vue')
          }, {
            path: 'update',
            name: 'ProductsPricesUpdate',
                meta: { permission: "products_edit" ,type : "child" },
                component: () => import ('../components/ProductsPrices/Update.vue')
          }
          , {
            path: 'show',
            name: 'ProductsPricesShow',
                meta: { permission: "product-read" ,type : "child" },
                component: () => import ('../components/ProductsPrices/Show.vue')
          }
        ]
      },
   {
        path: '/clients/B2B',
        name: 'B2B-client',
       meta: { permission: "clients" ,type : "parent" },
       component: () => import ('../components/Clients/List.vue'),
        children: [
          {
            path: 'create',
            name: 'ClientsCreate',
              meta: { permission: "clients_create" ,type : "child" },
              component: () => import ('../components/Clients/Create.vue')
          },
          {
            path: 'update',
            name: 'ClientsUpdate',
              meta: { permission: "clients_edit" ,type : "child" },
              component: () => import ('../components/Clients/Update.vue')
          }
        ]
      },
   {
        path: '/clients/partners-B2B',
        name: 'B2B-partner',
       meta: { permission: "clients" ,type : "parent" },
       component: () => import ('../components/Clients/List.vue'),
        children: [
          {
            path: 'create',
            name: 'ClientsCreate',
              meta: { permission: "clients_create" ,type : "child" },
              component: () => import ('../components/Clients/Create.vue')
          },
          {
            path: 'update',
            name: 'ClientsUpdate',
            meta: { permission: "clients_edit" ,type : "child" },
            component: () => import ('../components/Clients/Update.vue')
          }
        ]
      },
   {
        path: '/clients/individuals-partners',
        name: 'individual-partner',
       meta: { permission: "clients" ,type : "parent" },
       component: () => import ('../components/Clients/List.vue'),
        children: [
          {
            path: 'create',
            name: 'ClientsCreate',
              meta: { permission: "clients_create" ,type : "parent" },
              component: () => import ('../components/Clients/Create.vue')
          },
          {
            path: 'update',
            name: 'ClientsUpdate',
            meta: { permission: "clients_edit" ,type : "parent" },
            component: () => import ('../components/Clients/Update.vue')
          }
        ]
      },
   {
        path: '/clients/content-partners',
        name: 'contentPartners',
       meta: { permission: "clients" ,type : "parent" },
       component: () => import ('../components/Clients/List.vue'),
        children: [
          {
            path: 'create',
            name: 'ClientsCreate',
              meta: { permission: "clients_create" ,type : "child" },
              component: () => import ('../components/Clients/Create.vue')
          },
          {
              path: 'update',
              name: 'ClientsUpdate',
              meta: { permission: "clients_edit" ,type : "child" },
              component: () => import ('../components/Clients/Update.vue')
          }
        ]
      },
   {
        path: '/promo-codes',
        name: 'PromoCodes',
          meta: { permission: "promo_codes" ,type : "child" },
          component: () => import ('../components/PromoCodes/List.vue'),
        children: [
          {
            path: 'create',
            name: 'PromoCodesCreate',
            meta: { permission: "promo_codes_create" ,type : "child" },
              component: () => import ('../components/PromoCodes/Create.vue')
          },
          {
            path: 'update',
            name: 'PromoCodesUpdate',
            meta: { permission: "promo_codes_edit" ,type : "child" },
              component: () => import ('../components/PromoCodes/Update')
          },
          {
            path: 'show',
            name: 'PromoCodesShow',
            meta: { permission: "promo_codes_read" ,type : "child" },
              component: () => import ('../components/PromoCodes/Show.vue')
          },
        ]
      },
   {
            path: '/permissions',
            name: 'Permissions',
            meta: { permission: "permission" ,type : "child" },
            component: () => import ('../components/Permissions/List.vue'),
            children: [
                {
                    path: 'create',
                    name: 'PermissionsCreate',
                    meta: { permission: "permission_create" ,type : "child" },
                    component: () => import ('../components/Permissions/Create.vue')
                },
                {
                    path: 'update',
                    name: 'PermissionsUpdate',
                    meta: { permission: "permission_edit" ,type : "child" },
                    component: () => import ('../components/Permissions/Update.vue')
                }
            ]
        },
   {
        path: '/users',
        name: 'Users',
           meta: { permission: "user" ,type : "parent" },
           component: () => import ('../components/Users/List.vue'),
        children: [
          {
            path: 'create',
            name: 'UsersCreate',
              meta: { permission: "users_create" ,type : "child" },
              component: () => import ('../components/Users/Create.vue')
          },
          {
            path: 'update',
            name: 'UsersUpdate',
              meta: { permission: "users_edit" ,type : "child" },
              component: () => import ('../components/Users/Update.vue')
          }
        ]
      },
   {
        path: '/licenses',
        name: 'Licenses',
           meta: { permission: "licenses" ,type : "parent" },
           component: () => import ('../components/Licenses/List.vue'),
        children: [
          {
            path: 'create',
            name: 'LicensesCreate',
              meta: { permission: "licenses_create" ,type : "child" },
              component: () => import ('../components/Licenses/Create.vue')
          },
          {
            path: 'update',
            name: 'LicensesUpdate',
              meta: { permission: "licenses_edit" ,type : "child" },
              component: () => import ('../components/Licenses/Update.vue')
          },
          {
            path: 'show',
            name: 'LicensesShow',
              meta: { permission: "licenses_read" ,type : "child" },
              component: () => import ('../components/Licenses/Show.vue')
          },
          {
            path: 'renew',
            name: 'LicensesRenew',
              meta: { permission: "licenses_edit" ,type : "child" },
              component: () => import ('../components/Licenses/Renew.vue')
          },
        ]
      },
   {
        path: '/sms-templates',
        name: 'SMSTemplates',
           meta: { permission: "mail_sms_templates" ,type : "child" },
           component: () => import ('../components/SmsTemplates/List.vue'),
        children: [
          {
            path: 'create',
            name: 'SMSTemplatesCreate',
              meta: { permission: "mail_sms_templates_create" ,type : "child" },
              component: () => import ('../components/SmsTemplates/Create.vue')
          }, {
            path: 'update',
            name: 'SMSTemplatesUpdate',
                meta: { permission: "mail_sms_templates_edit" ,type : "child" },
                component: () => import ('../components/SmsTemplates/Update.vue')
          }, {
            path: 'show',
            name: 'SMSTemplatesShow',
                meta: { permission: "mail_sms_templates_read" ,type : "child" },
                component: () => import ('../components/SmsTemplates/Show.vue')
          }
        ]
      },
   {
            path: '/alerts',
            name: 'Alerts',
           meta: { permission: "notification_settings" ,type : "parent" },
           component: () => import ('../components/Alerts/List.vue'),
            children: [
                {
                    path: 'create',
                    name: 'AlertsCreate',
                    meta: { permission: "notification_settings_create" ,type : "child" },
                    component: () => import ('../components/Alerts/Create.vue')
                }, {
                    path: 'update',
                    name: 'AlertsUpdate',
                    meta: { permission: "notification_settings_edit" ,type : "child" },
                    component: () => import ('../components/Alerts/Update.vue')
                }, {
                    path: 'show',
                    name: 'AlertsShow',
                    meta: { permission: "notification_settings_read" ,type : "child" },
                    component: () => import ('../components/Alerts/Show.vue')
                }
            ]
        },
   {
            path: '/work-teams',
            name: 'WorkTeams',
            meta: { permission: "work_teams" ,type : "parent" },
            component: () => import ('../components/WorkTeams/List.vue'),
            children: [
                {
                    path: 'create',
                    name: 'WorkTeamsCreate',
                    meta: { permission: "work_teams_create" ,type : "child" },
                    component: () => import ('../components/WorkTeams/Create.vue')
                }, {
                    path: 'update',
                    name: 'WorkTeamsUpdate',
                    meta: { permission: "work_teams_edit" ,type : "child" },
                    component: () => import ('../components/WorkTeams/Update.vue')
                }, {
                    path: 'show',
                    name: 'WorkTeamsShow',
                    meta: { permission: "work_teams_read" ,type : "child" },
                    component: () => import ('../components/WorkTeams/Show.vue')
                }
            ]
        },
   {
            path: '/testimonials',
            name: 'Testimonials',
            meta: { permission: "testimonials" ,type : "parent" },
            component: () => import ('../components/Testimonials/List.vue'),
            children: [
                {
                    path: 'create',
                    name: 'TestimonialsCreate',
                    meta: { permission: "testimonials_create" ,type : "child"},
                    component: () => import ('../components/Testimonials/Create.vue')
                }, {
                    path: 'update',
                    name: 'TestimonialsUpdate',
                    meta: { permission: "testimonials_edit" ,type : "child"},
                    component: () => import ('../components/Testimonials/Update.vue')
                }, {
                    path: 'show',
                    name: 'TestimonialsShow',
                    meta: { permission: "testimonials_read" ,type : "child"},
                    component: () => import ('../components/Testimonials/Show.vue')
                }
            ]
        },
   {
            path: '/faqs',
            name: 'FAQS',
            meta: { permission: "faqs" ,type : "parent" },
            component: () => import ('../components/FAQS/List.vue'),
            children: [
                {
                    path: 'create',
                    name: 'FAQSCreate',
                    meta: { permission: "faqs_create" ,type : "child"},
                    component: () => import ('../components/FAQS/Create.vue')
                }, {
                    path: 'update',
                    name: 'FAQSUpdate',
                    meta: { permission: "faqs_edit" ,type : "child"},
                    component: () => import ('../components/FAQS/Update.vue')
                }, {
                    path: 'show',
                    name: 'FAQSShow',
                    meta: { permission: "faqs_read" ,type : "child"},
                    component: () => import ('../components/FAQS/Show.vue')
                }
            ]
        },
        {
            path: '/product-pages',
            name: 'ProductPages',
            meta: { permission: "product_pages" ,type : "parent" },
            component: () => import ('../components/ProductPages/List.vue'),
            children: [
                {
                    path: 'create',
                    name: 'ProductPagesCreate',
                    meta: { permission: "product_pages_create" ,type : "child"},
                    component: () => import ('../components/ProductPages/Create.vue')
                }, {
                    path: 'update',
                    name: 'ProductPagesUpdate',
                    meta: { permission: "product_pages_edit" ,type : "child"},
                    component: () => import ('../components/ProductPages/Update.vue')
                }, {
                    path: 'show',
                    name: 'ProductPagesShow',
                    meta: { permission: "product_pages_read" ,type : "child"},
                    component: () => import ('../components/ProductPages/Show.vue')
                }
            ]
        },

        {
            path: "/not-found",
            name: "NotFound",
            component: () => import ('../components/PageNotFound'),
        }



    ]

  },
  {
    path: '/login',
    name: 'Login',
    component: LoginView
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
router.beforeResolve((to, from, next) => {
  const publicPages = ['Login'];
  const authRequired = !publicPages.includes(to.name);
  const loggedIn = store.getters['auth/authenticated'];


  if (authRequired && !loggedIn) {
    return next('/login');
  }
  if(loggedIn && !authRequired) {
      return next('/');
  }

    if(authRequired && loggedIn && to.path !== '/') {
        let user = store.getters["auth/user"];
        let permissions = store.getters["auth/permissions"];

        if (user.role_id == 1 || (permissions.findIndex(p => p.name === to.meta.permission ) != -1 && to.meta.type == 'child') ||
            ( permissions.findIndex(p => p.model_name === to.meta.permission ) != -1  && to.meta.type == 'parent'  )

        ) {
            next();
        }else {
            return next('/');

        }
    }

    return next();


})

export default router
