<template>
  <label class="col-sm-3 col-form-label"  :class="{'text-danger': !input && required}">{{$t(code+'.text')}} <span v-if="languages.length > 1">{{ universal }}</span> <span v-if="required" class="text-danger">*</span></label>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: 'Help',
  props: {
    input :  String,
    code :  String,
    required:Boolean,
    universal:String

  },
  computed: {
    ...mapGetters({
      'languages': 'dashboard/languagesTabs',
      // ...
    })

  },


}
</script>


